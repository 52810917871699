import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Toast } from "../components";
import "react-toastify/dist/ReactToastify.css";
export const showToast = (
  msg: string,
  type: "info" | "success" | "error" = "info",

  options?: ToastOptions<{}>,
  link?: {
    href: string;
    text: string;
  }
) =>
  toast(
    ({ closeToast }) => (
      <Toast msg={msg} type={type} onClose={closeToast} link={link} />
    ),
    {
      ...options,
      position: "bottom-left",
    }
  );

export const showPersistentToast = (
  msg: string,
  type: "info" | "success" | "error" = "info",
  options?: ToastOptions<{}>
) =>
  toast(() => <Toast persistant msg={msg} type={type} />, {
    autoClose: 2000,
    style: { borderRadius: 8 },
    ...options,
  });
