import { createElement, cloneElement } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

interface LinkProps extends NextLinkProps {
  children: any;
  onClick?(e?: any): void;
}

export const Link = ({ children, href, ...props }: LinkProps) => {
  let to = href as string;
  const isExternal = /^https?:/.test(to);

  if (isExternal) {
    return (
      <>
        {!children?.type || children?.type?.displayName === 'FormattedMessage'
          ? createElement((props.as as any) || 'a', {
              children,
              href: to,
              ...(isExternal &&
              !to?.startsWith(process.env.NEXT_PUBLIC_PRIMARY_DOMAIN)
                ? { target: '_blank', rel: 'noopener noreferrer' }
                : {}),
              ...props,
            })
          : cloneElement(children, {
              href: to,
              ...(isExternal &&
              !to?.startsWith(process.env.NEXT_PUBLIC_PRIMARY_DOMAIN)
                ? { target: '_blank', rel: 'noopener noreferrer' }
                : {}),
              ...props,
            })}
      </>
    );
  }

  return (
    <NextLink href={to} passHref {...props} prefetch={false}>
      {!children?.type || children?.type?.displayName === 'FormattedMessage'
        ? createElement((props.as as any) || 'a', {
            children,
            ...props,
          })
        : cloneElement(children)}
    </NextLink>
  );
};
