import { createContext, FC, useContext } from "react";
import { useUser } from "@auth0/nextjs-auth0/client";
import { WebAuth } from "auth0-js";
import axios, { AxiosResponse } from "axios";
import { useRouter } from "next/router";
import { showPersistentToast, showToast } from "../../lib";
import {
  CustomerAccessTokenCreateInput,
  CustomerCreateInput,
  CustomerUpdateInput,
} from "../../lib/shopify/sdk";

type CustomerUpdate = CustomerUpdateInput & {
  emailConfirmation?: string;
  passwordConfirmation?: string;
  newPassword?: string;
};
export interface Auth0ContextType {
  auth0: WebAuth;
  auth0_login: (
    credentials: CustomerAccessTokenCreateInput,
    fromCheckout: boolean
  ) => void;
  auth0_logout: () => void;
  auth0_signup: (credentials: CustomerCreateInput) => void;
  // auth0_update: (
  //   input: CustomerUpdate,
  //   section: string
  // ) => Promise<AxiosResponse<boolean>>;
  auth0_resetPassword: (email: string) => Promise<void>;
}

export const Auth0Context = createContext<Auth0ContextType>(undefined);

export const Auth0Provider: FC = ({ children }) => {
  // const { user } = useUser();

  const auth0 = new WebAuth({
    domain: process.env.AUTH0_ISSUER_BASE_URL,
    clientID: process.env.AUTH0_CLIENT_ID,
    responseType: "code",
    redirectUri: process.env.AUTH0_REDIRECT_URL,
  });

  const auth0_login = async (
    input: CustomerAccessTokenCreateInput,
    fromCheckout: boolean
  ) => {
    auth0.login(
      {
        realm: process.env.AUTH0_SHOPIFY_DB,
        email: input.email,
        password: input.password,
        redirectUri: fromCheckout
          ? `${process.env.AUTH0_REDIRECT_URL}?fromCheckout`
          : process.env.AUTH0_REDIRECT_URL,
      },
      (err) => {
        return showToast(err.description, "error");
      }
    );
  };

  const auth0_signup = async (credentials: CustomerCreateInput) => {
    try {
      await axios.post(`/api/auth0/user/create`, credentials);
      await axios.get("/api/revalidate/signup");
    } catch (error) {
      console.log(error);
    }
  };

  // const auth0_update = async (input: CustomerUpdate, section: string) => {
  //   let data;
  //   if (section === "personal") {
  //     data = {
  //       given_name: input.firstName,
  //       family_name: input.lastName,
  //       name: `${input.firstName} ${input.lastName}`,
  //     };
  //   }
  //   if (section === "security") {
  //     data = {
  //       password: input.newPassword,
  //     };
  //   }
  //   try {
  //     return axios.post(`/api/auth0/user/update?user_id=${user.sub}`, {
  //       ...data,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const auth0_logout = () => {
    auth0.logout({
      clientID: process.env.AUTH0_CLIENT_ID,
    });
  };

  const auth0_resetPassword = async (email: string) => {
    return axios
      .get(`/api/auth0/reset-password?email=${email}`)
      .then((res) => {
        if (res) {
          showPersistentToast(res.data, "success");
        } else {
          showToast("This email does not exist", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("Something gone wrong!", "error");
      });
  };

  return (
    <Auth0Context.Provider
      value={{
        auth0,
        auth0_login,
        auth0_logout,
        auth0_signup,
        // auth0_update,
        auth0_resetPassword,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0 = () => {
  const ctx = useContext(Auth0Context);

  if (!ctx) {
    throw new Error("Context must be used within a context provider");
  }

  return ctx;
};
