import React, { forwardRef, ComponentProps, ReactNode, Ref } from "react";
import { Icon, SystemProps, Box, Spinner } from "@storyofams/react-ui";
import type { PolymorphicForwardRefExoticComponent } from "react-polymorphic-types";

import { useShopify } from "../../../context";
import { ArrowRight } from "../../../components/common/Icons";

import { Link } from "../Link";
import { StyledButton, variants, loadingStyles } from "./StyledButton";

const _defaultElement = "button";

export type ButtonProps = {
  isLoading?: boolean;
  icon?: ComponentProps<typeof Icon>["icon"];
  iconProps?: SystemProps;
  href?: string;
  hideArrow?: boolean;
  rel?: string;
  target?: string;
  variant?: keyof typeof variants;
  disabled?: boolean;
  children?: ReactNode;
  ariaLabel?: string;
};

const LinkButton = ({ content, href, variant, ...props }) => {
  let hrefParsed = href;

  // if (href?.includes("/tools/perfect-product-finder/")) {
  //   hrefParsed = `${href}?checkoutId=${checkout?.id}&storefrontAccessToken=${process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN}`;
  // }

  if (href === "menu-button") {
    return (
      <StyledButton as="a" variant={variant ?? "unstyled"} {...props}>
        {content}
      </StyledButton>
    );
  }

  return (
    <Link href={hrefParsed}>
      <StyledButton as="a" variant={variant ?? "unstyled"} {...props}>
        {content}
      </StyledButton>
    </Link>
  );
};

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonProps & SystemProps,
  typeof _defaultElement
> = forwardRef(
  (
    {
      children,
      icon,
      href,
      hideArrow,
      isLoading,
      variant,
      ariaLabel,
      iconProps,
      ...props
    }: ButtonProps,
    ref: Ref<any>
  ) => {
    const content = (
      <>
        {!!icon && <Icon width="24px" icon={icon} />}

        {children}

        {variant &&
          variant !== "unstyled" &&
          variant !== "link" &&
          variant !== "add_to_cart" &&
          !hideArrow && (
            <Icon
              className="button__arrow"
              width="24px"
              icon={<ArrowRight />}
            />
          )}
      </>
    );

    return href ? (
      <LinkButton content={content} variant={variant} href={href} {...props} />
    ) : (
      <StyledButton
        variant={variant ?? "primary"}
        as={_defaultElement}
        aria-label={ariaLabel}
        {...props}
        {...(isLoading ? loadingStyles : {})}
        ref={ref}
      >
        {isLoading ? (
          <Box
            color={"black"}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            {...iconProps}
          >
            <Spinner color="grey400" size={18 as any} />
          </Box>
        ) : (
          content
        )}
      </StyledButton>
    );
  }
);
