import { createGlobalStyle, css } from "styled-components";

const themeStyles = ({ theme }) => css`
  /* typograhpy */
  body {
    font-family: ${theme.fonts.body};
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: ${theme.fontSizes.heading}px;
      font-weight: ${(p) => p.theme.fontWeights.bold};
      font-family: ${theme.fonts.heading};
      line-height: ${(p) => p.theme.lineHeights.medium};
    }
  }

  html {
    font-size: ${theme.fontSizes[2]}px;
    font-weight: ${theme.fontWeights.normal};
    color: ${theme.colors.grey800};
    line-height: ${(p) => p.theme.lineHeights.medium};

    @media only screen and (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.fontSizes.root}px;
    }

    /* outline only when the user is using tab */
    &:not(.user-is-tabbing) {
      a[href],
      area[href],
      input:not([disabled]),
      select:not([disabled]),
      textarea:not([disabled]),
      button:not([disabled]),
      iframe,
      [tabindex],
      [contentEditable="true"] {
        outline: none;
      }
    }
  }

  .hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }
`;

const CSSreset = createGlobalStyle(
  ({ theme }) => css`
    html {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
    }
    body {
      margin: 0;
    }
    main {
      display: block;
    }
    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }
    pre {
      font-family: monospace, monospace;
      font-size: 1em;
    }
    a {
      background-color: transparent;
    }
    abbr[title] {
      border-bottom: none;
      text-decoration: underline;
      -webkit-text-decoration: underline dotted;
      text-decoration: underline dotted;
    }
    b,
    strong {
      font-weight: bolder;
    }
    code,
    kbd,
    samp {
      font-family: monospace, monospace;
      font-size: 1em;
    }
    small {
      font-size: 80%;
    }
    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }
    sub {
      bottom: -0.25em;
    }
    sup {
      top: -0.5em;
    }
    img {
      border-style: none;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: inherit;
      font-size: 100%;
      line-height: 1.15;
      margin: 0;
    }
    button {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      text-align: inherit;
      background: transparent;

      color: inherit;
      font: inherit;

      line-height: normal;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      -webkit-appearance: none;
    }
    button,
    input {
      overflow: visible;
    }
    button,
    select {
      text-transform: none;
    }
    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    fieldset {
      padding: 0.35em 0.75em 0.625em;
    }
    legend {
      box-sizing: border-box;
      color: inherit;
      display: table;
      max-width: 100%;
      padding: 0;
      white-space: normal;
    }
    progress {
      vertical-align: baseline;
    }
    textarea {
      overflow: auto;
    }
    [type="checkbox"],
    [type="radio"] {
      appearance: none;
      -webkit-appearance: none;
      box-sizing: border-box;
      padding: 0;
    }
    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    [type="search"] {
      -webkit-appearance: none;
      border-radius: 8px;
      outline-offset: -2px;
    }
    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none !important;
    }
    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }
    details {
      display: block;
    }
    summary {
      display: list-item;
    }
    template {
      display: none;
    }
    [hidden] {
      display: none !important;
    }
    html {
      box-sizing: border-box;
      font-family: sans-serif;
    }
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
    blockquote,
    dl,
    dd,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    figure,
    p,
    pre {
      margin: 0;
    }
    button {
      background: transparent;
      padding: 0;
    }
    fieldset {
      margin: 0;
      padding: 0;
    }
    ol,
    ul {
      margin: 0;
      padding: 0;
    }
    html {
      font-family: ${theme.fonts.body};
      line-height: 1.5;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
      text-rendering: optimizelegibility;
    }
    hr {
      border-top-width: 1px;
    }
    textarea {
      resize: vertical;
    }
    button,
    [role="button"] {
      cursor: pointer;
    }
    button::-moz-focus-inner {
      border: 0 !important;
    }
    table {
      border-collapse: collapse;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: inherit;
      font-weight: inherit;
    }
    a {
      color: inherit;
      text-decoration: inherit;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
      padding: 0;
      line-height: inherit;
      color: inherit;
    }
    pre,
    code,
    kbd,
    samp {
      font-family: ${theme.fonts.mono};
    }
    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
      display: block;
      vertical-align: middle;
    }
    img,
    video {
      max-width: 100%;
      height: auto;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    .Toastify__toast-container {
      width: calc(100% - 16px);
      padding: 0;

      @media only screen and (min-width: 600px) {
        width: 400px;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .Toastify__toast {
      background-color: transparent;
      padding: 0;
      margin-bottom: 0;
      width: 100%;
      box-shadow: none;
      border-radius: ${theme.radii.xs} !important;
    }

    .Toastify__toast-body {
      background-color: transparent;
      padding: 0;
      width: 100%;
    }

    #nprogress {
      z-index: 9999999;

      .bar {
        z-index: 9999999;
        background: ${theme.colors.oldPink};
      }

      .peg {
        box-shadow: 0 0 10px ${theme.colors.oldPink},
          0 0 5px ${theme.colors.oldPink};
      }

      .spinner {
        display: none;
      }
    }
    [data-reach-menu-popover] {
      z-index: 9999;
    }
    [data-reach-menu-list] {
      display: block;
      white-space: nowrap;
      border: solid 1px hsla(0, 0%, 0%, 0.25);
      background: hsla(0, 100%, 100%, 0.99);
      border-radius: 6px;

      outline: none;
      padding: 0;
      font-size: 85%;
    }

    [data-reach-menu-item] {
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    [data-reach-menu-item][data-selected] {
      background: none;
      color: #000;
    }

    .active[data-reach-menu-item] {
      background: ${theme.colors.grey800};
      color: ${theme.colors.white};
    }
    #chat-button {
      @media (max-width: 768px) {
        display: none;
      }
    }
    ${themeStyles}
  `
);

export default CSSreset;
