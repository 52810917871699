import { ToastContainer } from 'react-toastify';

export const Container = () => {
  return (
    <ToastContainer
      position="bottom-right"
      closeButton={false}
      autoClose={4000}
      pauseOnFocusLoss
      hideProgressBar
      pauseOnHover
    />
  );
};
