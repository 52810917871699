import { Box, system, SystemProps } from "@storyofams/react-ui";
import styled from "styled-components";
import { variant, ResponsiveValue } from "styled-system";

export const textVariants = {
  md: {
    fontWeight: "medium",
  },
  lg: {
    fontSize: 2.5,
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: 2,
    lineHeight: "medium",
  },
};

type CustomProps = {
  variant?:
    | ResponsiveValue<keyof typeof textVariants>
    | keyof typeof textVariants;
  as?: string;
} & SystemProps;

export const Text = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? "p",
}))<CustomProps>`
  line-height: ${(p) => p.theme.lineHeights.high};

  ${variant({ variants: textVariants })}
  ${system};
`;
