import { v4 as uuidv4 } from "uuid";

declare interface DataLayerWindow extends Window {
  dataLayer?: any;
  ElevarPushToDataLayer?: any;
}
declare const window: DataLayerWindow;

const LOCAL_STORAGE_PRODUCT_LIST_KEY = "productClickOrigins";

export const formatAlgoliaProductsForTracking = (searchResults: any) =>
  searchResults?.hits?.map((hit) => ({
    name: hit.title,
    id: hit.sku || "",
    product_id: `${hit.id}`,
    variant_id: `${hit.objectID || ""}`,
    price: `${hit.price}`,
    brand: hit.vendor || "",
    position: hit.position - 1,
    category: hit.product_type || "",
    list: window.location.pathname,
    variant: `${hit.objectID || ""}`
  })) || [];

export const formatShopifyIdForTracking = (id: string) => {
  if (id?.includes("gid")) return id.replace(/gid:\/\/shopify\/.*\//i, "");
  return id ? window.atob(id)?.replace(/gid:\/\/shopify\/.*\//i, "") : null;
};

export const getProductClickOrigin = (productId: string) => {
  try {
    const stored = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_PRODUCT_LIST_KEY)
    );

    return stored?.[productId] || "";
  } catch (e) {
    return "";
  }
};

export const storeProductClickOrigin = (productId: string, origin: string) => {
  let productClickOrigins = {};

  try {
    const stored = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_PRODUCT_LIST_KEY)
    );

    if (stored) {
      productClickOrigins = stored;
    }
  } catch (e) {}

  if (Object.keys(productClickOrigins)?.length > 50) {
    delete productClickOrigins[Object.keys(productClickOrigins)[0]];
  }

  productClickOrigins[productId] = origin;

  localStorage.setItem(
    LOCAL_STORAGE_PRODUCT_LIST_KEY,
    JSON.stringify(productClickOrigins)
  );
};

export const track = (event) => {
  // window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({ ...event });
  (window as any).ElevarDataLayer = (window as any)?.ElevarDataLayer ?? [];
  (window as any)?.ElevarDataLayer.push({ ...event });
};
