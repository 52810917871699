import { Box, system } from "@storyofams/react-ui";
import styled from "styled-components";
import { variant } from "styled-system";
import { ButtonProps } from ".";

const baseStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  border: "0",
  userSelect: "none",
  borderRadius: "md",
  transition:
    "background-color 0.18s ease-in-out, box-shadow 0.18s, border-color 0.18s ease-in-out, color 0.18s ease-in-out, opacity 0.18s ease-in-out, color 0.18s ease-in-out, width 0.18s ease-in-out, padding 0.18s ease-in-out",
};

const baseButton = {
  ...baseStyles,
  border: "1px solid",
  borderColor: "grey800",
  fontSize: 2.25,
  fontWeight: "medium",
  minHeight: "54px",
  px: [5, 5],
  py: 2,

  ".button__arrow": {
    width: 0,
    pl: 0,
    transition: "width 0.18s ease-in-out, padding-left 0.18s ease-in-out",
    overflow: "hidden",
  },

  "&:not(:disabled)": {
    "&:hover, &:focus, &:active": {
      px: [3, 6],

      ".button__arrow": {
        width: "32px",
        pl: 1,
      },
    },
  },
};

const baseLink = {
  ...baseStyles,
  fontSize: 2.25,
  fontWeight: "medium",
  color: "black",
  display: "inline-flex",
};

export const variants = {
  primary: {
    ...baseButton,
    color: "white",
    bg: "grey800",
  },
  add_to_cart: {
    ...baseButton,
    color: "white",
    bg: "grey800",
  },
  secondary: {
    ...baseButton,
    color: "grey800",
  },
  link: {
    ...baseLink,

    "&:not(:disabled)": {
      "&:hover, &:active, &:focus": {
        opacity: 0.7,
      },
    },
  },
  "link-arrow": {
    ...baseLink,

    ".button__arrow": {
      pl: 1,
      width: "32px",
      transition: "transform 0.18s ease-in-out",
    },

    "&:not(:disabled)": {
      "&:hover, &:active, &:focus": {
        ".button__arrow": {
          transform: "translateX(4px)",
        },
      },
    },
  },
  unstyled: {
    "&:disabled": { cursor: "not-allowed", color: "grey500" },
  },
};

export const loadingStyles = {
  position: "relative" as any,
  disabled: true,
  "data-is-loading": true,
  "aria-disabled": true,
};

export const StyledButton = styled(Box)<Pick<ButtonProps, "variant">>`
  position: relative;
  appearance: none;
  text-decoration: none;

  &:active {
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }

  &[data-is-loading] {
    cursor: wait;
    opacity: 1;
  }

  ${variant({ variants })}
  ${system}
`;
